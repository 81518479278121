:root {
  --hover-gradient: linear-gradient(45deg, rgb(255, 182, 65), rgb(255, 62, 0) 45%, rgb(168, 97, 255) 80%);
  --header-gradient: linear-gradient(45deg, rgb(255, 182, 65) 20%, rgb(255, 62, 0) 45%, rgb(168, 97, 255) 80%);
  --header-low-gradient: linear-gradient(45deg, rgb(255, 182, 65), rgb(255, 87, 34));
}

@import url(http://fonts.googleapis.com/css?family=Lato:300,400,700,900);
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  padding: 20px;
  border: 1px solid #b3b3b3;
  min-height: 450px;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: none;
}

.video-col {
  margin-right: 8.333333%;
}

.choose-app {
  padding-bottom: 0%;
}

.about-app {
  padding-bottom: 0%;
}
.features-app {
  padding-bottom: 0%;
}

.details-app {
  padding-bottom: 0%;
}

.section-heading {
  margin: 60px 0 80px;
  text-align: center;
}

.image-features {
  margin: 0px 60px 80px;
  /*justify-content: center; does not work*/
  /*align-items: center; does not work*/
}
.text-right-block-features {
  margin: 0px 25px 0px;
  /*justify-content: center; does not work*/
  /*align-items: center; does not work*/
}

.audio {
  margin: 0 auto;
  display: block;
}

body {
  font-family: 'Lato', Arial;
  color: #666666;
  -webkit-font-smoothing: antialiased;
}
a {
  font-family: 'Lato', Arial;
  -webkit-font-smoothing: antialiased;
}
div.content {
  overflow: hidden ;
}
h1,
h2 {
  color: #ff9577;
}
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #666666;
}
header .navbar-default {
  background: none;
  border: none;
  padding-top: 40px;
  padding-bottom: 20px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default ul.navbar-nav {
  padding-top: 55px;
}
header .navbar-default ul.navbar-nav li a {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  padding: 7px 20px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default ul.navbar-nav li a:after {
  content: '';
  width: 32px;
  background: none;
  height: 4px;
  position: absolute;
  bottom: 2px;
  z-index: 99999;
  left: 20px;
  -webkit-border-radius: 5em;
  -moz-border-radius: 5em;
  -ms-border-radius: 5em;
  -o-border-radius: 5em;
  border-radius: 5em;
  color: #ffffff;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default ul.navbar-nav li a:hover {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default ul.navbar-nav li a:hover:after {
  background: #ffffff;
}
header .navbar-default ul.navbar-nav li a.getApp {
  -webkit-border-radius: 5em;
  -moz-border-radius: 5em;
  -ms-border-radius: 5em;
  -o-border-radius: 5em;
  border-radius: 5em;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default ul.navbar-nav li a.getApp:after {
  display: none;
}
header .navbar-default ul.navbar-nav li a:focus {
  color: #ffffff;
}
header .navbar-default .navbar-brand {
  height: auto;
  padding: 0;
}
header .navbar-default.scrolled {
  background: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  padding: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default.scrolled .navbar-brand {
  padding: 15px 20px;
}
header .navbar-default.scrolled .navbar-brand img {
  width: auto;
  max-height: 33px;
}
header .navbar-default.scrolled ul.navbar-nav {
  padding-top: 15px;
}
.scrollpoint {
  opacity: 0;
}
.scrollpoint.active {
  opacity: 1 \0;
}
.hanging-phone {
  position: absolute;
  right: 0;
  z-index: 0;
  bottom: -70px;
}

.section-heading {
  margin: 20px 0 30px;
  text-align: center;
}
.section-heading h1 {
  display: inline-block;
  font-size: 55px;
  font-weight: 600;
  margin-bottom: 0;
}
.section-heading p {
  font-size: 25px;
  color: #999999;
  font-weight: 300;
}
.section-heading .divider:after {
  content: "";
  position: relative;
  height: 4px;
  width: 60px;
  display: block;
  text-align: center;
  margin: 13px auto;
  -webkit-border-radius: 5em;
  -moz-border-radius: 5em;
  -ms-border-radius: 5em;
  -o-border-radius: 5em;
  border-radius: 5em;
}
.section-heading.inverse h1,
.section-heading.inverse p {
  color: #ffffff;
}
.section-heading.inverse .divider:after {
  background: #ffffff;
}

/* Get app Section */
.top-div {
  position: relative;
  overflow: hidden;
  padding: 10px 0;
  padding-bottom: 10%;
}

.background-div {
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 650px);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.7);  /* Makes it darker */
  z-index: -1;
  filter: blur(8px) brightness(0.3);
}

.top-div .platforms {
  margin: 5px auto 30px auto;
  text-align: center;
}
.top-div .platforms .btn {
  margin-right: 10px;
  margin-bottom: 20px;
}

/* footer section */
footer {
  padding: 30px 0;
  text-align: center;
}
footer p > img {
  width: 100px;
  margin-right: 20px;
}
footer .social {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
footer .social a {
  margin: 0 8px;
  padding: 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
footer .social a i {
  width: 1em;
}
footer .rights {
  margin-top: 30px;
}
footer .rights a {
  text-decoration: underline;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  font-weight: bold;
}
footer .rights a:hover {
  text-decoration: none;
}
section {
  position: relative;
  padding-bottom: 90px;
}
/* Common style for pseudo-elements */
section::before,
section::after {
  position: absolute;
  content: '';
  pointer-events: none;
  overflow: hidden;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.tp-caption {
  font-family: 'Lato', Arial !important;
  -webkit-font-smoothing: antialiased;
}
.tp-caption.large_white_bold {
  color: #ffffff;
  font-size: 65px;
  font-weight: 400;
}
.tp-caption.large_white_light {
  color: #ffffff;
  font-size: 65px;
  font-weight: 400;
  font-weight: 300;
}
.btn {
  -webkit-border-radius: 3em;
  -moz-border-radius: 3em;
  -ms-border-radius: 3em;
  -o-border-radius: 3em;
  border-radius: 3em;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 5px 25px;
}
.btn.custom-flex-btn {
  width: auto;
  display: inline-flex;
  align-items: center; /* To vertically align items in the middle */
  justify-content: flex-start; /* To align content to the left */
  flex-wrap: nowrap; /* To prevent wrapping */
  text-align: center; /* Just in case there's a text-align: center applied somewhere */
}
.icon-wrapper {
  flex: 0; /* Doesn't grow in size, remains as is */
  margin-right: 10px; /* Give some space between the icon and the text */
}
.icon-playcircle {
  font-size: 42px;
}
.icon-googleplay {
  font-size: 38px;
}
.icon-appstore {
  font-size: 42px;
}
.icon-telegram {
  font-size: 42px;
}
.icon-facebook {
  font-size: 40px;
}
.icon-xtwitter {
  font-size: 38px;
}
.text-wrapper > div:first-child {
  font-size: 11px;
  text-align: left;
}

.text-wrapper > div:last-child b {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}
.btn.btn-primary {
  border: 2px solid #ffffff;
  background: none;
  color: #ffffff;
}
.btn.btn-primary:hover {
  color: #ffffff;
}
.btn.btn-default {
  border: none;
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #ffffff), color-stop(100%, #cccccc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* IE10+ */
  background: linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ffffff, endColorstr=#cccccc, GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.btn.btn-lg {
  padding: 10px 25px;
}
.btn:hover {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
form .form-control-about {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5em;
  -moz-border-radius: 5em;
  -ms-border-radius: 5em;
  -o-border-radius: 5em;
  border-radius: 5em;
  height: 40px;
  line-height: 40px;
  padding: 10px 20px;
  color: #cfcfcf;
  border: 2px solid #cccccc;
  font-weight: 300;
}

form button.form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5em;
  -moz-border-radius: 5em;
  -ms-border-radius: 5em;
  -o-border-radius: 5em;
  border-radius: 5em;
  height: 40px;
  line-height: 30px;
  padding: 0px 0px;
  color: #092f6f;
  border: 2px solid #cccccc;
  font-weight: bold;
}

form textarea.form-control {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  height: auto;
  line-height: normal;
}
@media screen and (max-width: 640px) {
  .logo {
    width: 100px;
    height: auto;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }
  .contact-details {
    margin-top: 20px;
  }
  header .navbar-default {
    padding: 20px;
  }
  header .navbar-default .navbar-toggle {
    margin-top: 0px;
    margin-right: 0px;
    background: none;
    border: 2px solid #ffffff;
    color: #ffffff;
    -webkit-border-radius: 5em;
    -moz-border-radius: 5em;
    -ms-border-radius: 5em;
    -o-border-radius: 5em;
    border-radius: 5em;
  }
  header .navbar-default .navbar-toggle:hover,
  header .navbar-default .navbar-toggle:focus {
    background: none;
  }
  header .navbar-default ul.navbar-nav {
    padding-top: 0;
    margin: 0;
  }
  header .navbar-default ul.navbar-nav li a {
    padding: 10px;
    text-align: center;
  }
  header .navbar-default ul.navbar-nav li a.getApp {
    background: none !important;
    -webkit-border-radius: 0em;
    -moz-border-radius: 0em;
    -ms-border-radius: 0em;
    -o-border-radius: 0em;
    border-radius: 0em;
  }
  header .navbar-default .navbar-collapse {
    border-color: transparent;
  }
  header .navbar-default.scrolled .navbar-collapse {
    border-color: transparent;
  }
  header .navbar-default.scrolled ul.navbar-nav {
    padding-top: 0;
    margin: 0;
  }
  header .navbar-default.scrolled .navbar-toggle {
    margin-top: 11px;
    margin-right: 20px;
    background: none;
    border: 2px solid #ffffff;
    color: #ffffff;
    -webkit-border-radius: 5em;
    -moz-border-radius: 5em;
    -ms-border-radius: 5em;
    -o-border-radius: 5em;
    border-radius: 5em;
  }
}
.pre-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: none;
}
.pre-loader .load-con {
  margin: 20% auto;
  position: relative;
  text-align: center;
}
.spinner {
  margin: 50px auto 0;
  width: 70px;
  text-align: center;
}
.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
header {
  background: #66cdcc;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #66cdcc), color-stop(100%, #336799));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);
  /* IE10+ */
  background: linear-gradient(-45deg, #66cdcc 0%, #336799 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#66cdcc, endColorstr=#336799, GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
header .navbar-default ul.navbar-nav li a {
  color: #ffffff;
  background: none;
}
header .navbar-default ul.navbar-nav li a.getApp {
  color: #3c79b4;
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #ffffff), color-stop(100%, #e0e0e0));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  /* IE10+ */
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ffffff, endColorstr=#e0e0e0, GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
header .navbar-default ul.navbar-nav li a.getApp:hover {
  color: #4081bf;
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #ffffff), color-stop(100%, #cccccc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* IE10+ */
  background: linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ffffff, endColorstr=#cccccc, GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default ul.navbar-nav li a:hover {
  color: #ffffff;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
header .navbar-default.scrolled {
  border-bottom: 3px solid #336799;
}
header .navbar-default.scrolled ul.navbar-nav li a {
  color: #336799;
}
header .navbar-default.scrolled ul.navbar-nav li a:hover {
  color: #336799;
}
header .navbar-default.scrolled ul.navbar-nav li a:hover:after {
  background: #336799;
}
header .navbar-default.scrolled .navbar-brand {
  background: #336799;
}
section .section-heading .divider:after {
  background: #ff9577;
}

/* Get app Section */
/*.top-div {*/
  /*background: url("../img/freeze/bk-freeze-ga.jpg");*/
/*}*/
.top-div header {
  color: #ffffff;
}
.top-div header h1:after {
  background: #ffffff;
}
.top-div .platforms .media {
  border: 2px solid #ffffff;
  color: #ffffff;
}
.top-div .platforms .media a i {
  color: #ffffff;
}
.top-div .platforms .media a div.media-body {
  color: #ffffff;
}
.top-div .platforms .media:hover {
  background: #ffffff;
}
.top-div .platforms .media:hover a i,
.top-div .platforms .media:hover a div.media-body {
  color: #ff9577;
}

/* footer section */
footer {
  /*background: #66cdcc;*/
  /* Old browsers */
  /*background: -moz-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);*/
  /* FF3.6+ */
  /*background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #66cdcc), color-stop(100%, #336799));*/
  /* Chrome,Safari4+ */
  /*background: -webkit-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);*/
  /* Chrome10+,Safari5.1+ */
  /*background: -o-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);*/
  /* Opera 11.10+ */
  /*background: -ms-linear-gradient(-45deg, #66cdcc 0%, #336799 100%);*/
  /* IE10+ */
  /*background: linear-gradient(-45deg, #66cdcc 0%, #336799 100%);*/
  /* W3C */
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#66cdcc, endColorstr=#336799, GradientType=1);*/
  /* IE6-9 fallback on horizontal gradient */
}
footer .social a {
  border: 2px solid #ffffff;
}
footer .social a i {
  color: #ffffff;
}
footer .social a:hover {
  background: var(--hover-gradient);
  border-color: white;
}
.social .fa {
  color: white;
}
footer .rights {
  color: #ffffff;
}
footer .rights a {
  color: #ffffff;
  font-weight: 300;
  text-decoration: none;
}
.navbar-toggle {
  background-color: #CCC;
  border: 1px solid rgba(0, 0, 0, 0);
}
.btn.btn-primary {
  color: #ff9577;
  border-color: #ff9577;
}
.btn.btn-primary:hover {
  color: #ffffff;
  background: #ff9577;
  border-color: #ff9577;
}
.btn.btn-primary.inverse {
  color: #ffffff;
  border-color: #ffffff;
}
.btn.btn-primary.inverse:hover {
  color: #ffffff;
  background: var(--hover-gradient) !important;
  border-color: white;
}
.btn.btn-default {
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #ffffff), color-stop(100%, #cccccc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* IE10+ */
  background: linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ffffff, endColorstr=#cccccc, GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  color: #336799;
}
.btn.btn-default:hover {
  color: #336799;
}
form .form-control:focus {
  border-color: #ff9577;
  color: #999999;
}
@media screen and (max-width: 640px) {
  header .navbar-default .navbar-toggle:hover,
  header .navbar-default .navbar-toggle:focus {
    background: none;
  }
  header .navbar-default ul.navbar-nav li a {
    color: #ffffff;
    border-bottom: 1px solid #376fa4;
  }
  header .navbar-default ul.navbar-nav li a.getApp {
    color: #ffffff;
  }
  header .navbar-default ul.navbar-nav li a:after {
    display: none;
  }
  header .navbar-default ul.navbar-nav li a:hover {
    color: #ff9577;
  }
  header .navbar-default .navbar-collapse {
    background: #336799;
  }
  header .navbar-default.scrolled {
    background: #336799;
  }
  header .navbar-default.scrolled ul.navbar-nav li a {
    color: #ffffff;
  }
  header .navbar-default.scrolled ul.navbar-nav li a:hover {
    color: #ff9577;
  }
}
.pre-loader {
  background: #336799;
}

a.about:hover {
  text-decoration:none;
}

/* This targets the entire scrollbar */
::-webkit-scrollbar {
  width: 10px;  /* Adjust width of the vertical scrollbar */
}

/* This targets the track (or background) of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #2C2C2C; /* Dark background for the track */
}

/* This targets the handle (or thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #555;  /* Darker color for the thumb */
  border-radius: 5px;  /* Makes the handle rounded */
  background-clip: padding-box;
  border: 2px solid transparent;  /* Adds some space between the handle and the track */
}

/* This targets the handle (or thumb) when it's hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #777;  /* A bit lighter color when hovered */
}

.custom-icon {
  width: 50px;
  height: 50px;
  margin-top: -14px;
  margin-right: 2px;
  vertical-align: middle;
}

/* Non-clicked links (Default state) */
a:link {
  color: #ff9577;
  text-decoration: none;  /* To remove underlines.*/
}

/* Clicked (Visited) links */
a:visited {
  color: #ff9577;
  text-decoration: none;  /* To remove underlines. */
}

/* Color on hover */
a:hover {
  color: #ff9577;
}

/* When active (being clicked) */
a:active {
  color: #ff9577;
}

.gradient-header {
    background: var(--header-gradient);
    -webkit-background-clip: text;
    color: transparent;
}

.gradient-header-low {
    background: var(--header-gradient);
    -webkit-background-clip: text;
    color: transparent;
}
.spacer {
  display: block;
  height: 10px;
  width: 100%;
}