.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    /*margin: 0;*/
    margin-bottom: 10pt;
    width: 100% !important;
    min-width: 480pt;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(../img/upload.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

div.file-list p {
    margin-bottom: 0;
    color: black;
}

.dmca-description {
    width: 100% !important;
    border-radius: 2px;
    margin-bottom: 20px;
}

.dmca-form-required {
    color: red;
    margin-right: 5px;
}

.dmca-top {
    min-height: 1080pt;
}

textarea.dmca-description {
    min-height: 60px;
    height: fit-content;
}

.complaint-app {
    border: thin #000000;
    /*border-style: inset;*/
    border-radius: 2px;
    max-width: max-content;
    color: black;
}

input[type='checkbox'] {
    margin-right: 5pt  ;
}

.dmca-accept {
    background-color: rgba(255, 255, 220, 0.85);
    border-radius: 2px;
    border: thin #000000;
    border-style: inset;
    padding: 0px;
}

.dmca-submit {
    max-width: 200pt;
}

.dmca-submit:disabled {
    background-color: gray;
}

.dmca-section-long {
    min-width: 640pt;
    padding: 10px;
}

.dmca-section-short {
    min-width: 320pt;
    max-width: max-content;
}

.dmca-phone img {
    vertical-align: top;
}

input:invalid {
    background-color: pink;
}

.req-input {
    background-color: pink;
}